<template>
  <my-window :width="700" :height="600" :left="left" :top="top" title="Configuración" :closeAction="toggleShowConfig"
    :actions="actions">
    <v-tabs v-model="tab" fixed-tabs background-color="secondary" dark>
      <v-tab>
        Camaras
      </v-tab>
      <v-tab>
        Transporte
      </v-tab>
      <v-tab>
        LPRs
      </v-tab>
      <v-tab>
        Unidades
      </v-tab>
      <v-tab>
        Botones de pánico
      </v-tab>
      <v-tab>
        Incidentes
      </v-tab>
      <v-tab>
        Social
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" style="height: calc(100% - 48px); overflow: auto;">
      <v-tab-item :eager="true">
        <my-form ref="form_cameras" :schema="form_cameras" :item="config.cameras"></my-form>
      </v-tab-item>
      <v-tab-item :eager="true">
        <my-form ref="form_buses" :schema="form_buses" :item="config.buses"></my-form>
      </v-tab-item>
      <v-tab-item :eager="true">
        <my-form ref="form_lprs" :schema="form_lprs" :item="config.lprs"></my-form>
      </v-tab-item>
      <v-tab-item :eager="true">
        <my-form ref="form_devices" :schema="form_devices" :item="config.devices"></my-form>
      </v-tab-item>
      <v-tab-item :eager="true">
        <my-form ref="form_sensors" :schema="form_sensors" :item="config.sensors"></my-form>
      </v-tab-item>
      <v-tab-item :eager="true">
        <my-form ref="form_incidents" :schema="form_incidents" :item="config.incidents"></my-form>
      </v-tab-item>
      <v-tab-item :eager="true">
        <v-list class="pa-4 overflow-y-auto" style="background: transparent;" dark>
          <template v-for="(item, index) in keywords">
            <div :key="index + 'k'" class="d-flex">
              <v-text-field :disabled="!getUser.attributes.twitterGroupAdmin" v-model="keywords[index].keyword"
                hide-details light class="mb-1" @keyup.enter="saveKeyword(keywords[index].keyword)">
              </v-text-field>
              <v-btn v-if="!keywords[index].saved" icon>
                <v-icon color="secondary" @click="saveKeyword(keywords[index].keyword)">mdi-content-save</v-icon>
              </v-btn>
              <v-btn v-if="keywords[index].saved" icon>
                <v-icon color="red" @click="removeKeyword(index)">mdi-close</v-icon>
              </v-btn>
            </div>
          </template>
        </v-list>
      </v-tab-item>
    </v-tabs-items>
  </my-window>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import UserService from '@/util/users.service'

export default {
  components: {
    'my-window': () => import('@/components/Window.vue'),
    'my-form': () => import('@/components/form/Form.vue'),
  },
  props: {
    tweetStore: Object,
    groups: Array
  },
  data() {
    return {
      tab: null,
      twitter_keywords: [],
      actions: [{
        icon: 'mdi-account-key',
        action: () => {
          UserService.changePassword({
            user: this.getUser,
          }, this)
        }
      }],
      form_cameras: {
        status: {
          label: 'Mostrar camaras',
          type: Array,
          options: [{
            id: 'up',
            name: 'En linea',
          }, {
            id: 'down',
            name: 'Fuera de linea',
          }],
          inline: true,
          onChange: this.setCamerasFilter
        },
        'status.$': {
          type: String,
          blackbox: true,
        },
        name: {
          label: 'Nombre',
          type: String,
          changeOnLoad: false,
          optional: true,
          onChange: this.setCamerasFilter,
          delay: 500
        },
        groupId: {
          label: 'Grupo',
          type: String,
          changeOnLoad: false,
          optional: true,
          multiple: true,
          options: [],
          onChange: this.setCamerasFilter
        }
      },
      form_buses: {
        status: {
          label: 'Mostrar transporte',
          type: Array,
          options: [{
            id: 'up',
            name: 'En linea',
          }, {
            id: 'down',
            name: 'Fuera de linea',
          }, {
            id: 'alert',
            name: 'Alertado'
          }, {
            id: 'reported',
            name: 'Reportado'
          }],
          inline: true,
          onChange: this.setBusesFilter
        },
        'status.$': {
          type: String,
          blackbox: true,
        },
        name: {
          label: 'Nombre',
          type: String,
          changeOnLoad: false,
          optional: true,
          onChange: this.setBusesFilter,
          delay: 500
        },
        groupId: {
          label: 'Grupo',
          type: String,
          changeOnLoad: false,
          optional: true,
          multiple: true,
          options: [],
          onChange: this.setBusesFilter
        }
      },
      form_lprs: {
        status: {
          label: 'Mostrar lprs',
          type: Array,
          options: [{
            id: 'up',
            name: 'En linea',
          },
          {
            id: 'alert',
            name: 'En alerta',
          },
          {
            id: 'down',
            name: 'Fuera de linea',
          }],
          inline: true,
          onChange: this.setLprsFilter
        },
        'status.$': {
          type: String,
          blackbox: true,
        },
        name: {
          label: 'Nombre',
          type: String,
          changeOnLoad: false,
          optional: true,
          onChange: this.setLprsFilter,
          delay: 500
        },
        groupId: {
          label: 'Grupo',
          type: String,
          changeOnLoad: false,
          optional: true,
          multiple: true,
          options: [],
          onChange: this.setLprsFilter
        }
      },
      form_devices: {
        status: {
          label: 'Mostrar unidades',
          type: Array,
          options: [{
            id: 'online',
            name: 'En linea',
          }, {
            id: 'unknown',
            name: 'Desconocido',
          }, {
            id: 'offline',
            name: 'Fuera de linea',
          }],
          inline: true,
          onChange: this.setDevicesFilter
        },
        'status.$': {
          type: String,
          blackbox: true,
        },
        name: {
          label: 'Nombre',
          type: String,
          changeOnLoad: false,
          optional: true,
          onChange: this.setDevicesFilter,
          delay: 500
        },
        groupId: {
          label: 'Grupo',
          type: String,
          changeOnLoad: false,
          optional: true,
          multiple: true,
          options: [],
          onChange: this.setDevicesFilter
        }
      },
      form_sensors: {
        status: {
          label: 'Mostrar botones de panico',
          type: Array,
          options: [{
            id: 'up',
            name: 'En linea',
          }, {
            id: 'down',
            name: 'Fuera de linea',
          }, {
            id: 'alert',
            name: 'Alertado'
          }, {
            id: 'reported',
            name: 'Reportado'
          }],
          inline: true,
          onChange: this.setSensorsFilter
        },
        'status.$': {
          type: String,
          blackbox: true,
        },
        name: {
          label: 'Nombre',
          type: String,
          changeOnLoad: false,
          optional: true,
          onChange: this.setSensorsFilter,
          delay: 500
        },
        groupId: {
          label: 'Grupo',
          type: String,
          changeOnLoad: false,
          optional: true,
          multiple: true,
          options: [],
          onChange: this.setSensorsFilter
        }
      },
      form_incidents: {
        category: {
          label: 'Tipos de incidente',
          type: Array,
          options: [{
            id: 'proteccion_civil',
            name: 'Protección civil',
          }, {
            id: 'servicios_publicos',
            name: 'Servicios públicos',
          }, {
            id: 'medico',
            name: 'Médico',
          }, {
            id: 'seguridad',
            name: 'Seguridad',
          }],
          inline: true,
          onChange: this.setIncidentsFilter
        },
        'category.$': {
          type: String,
          blackbox: true,
        },
        groupId: {
          label: 'Grupo',
          type: String,
          changeOnLoad: false,
          optional: true,
          multiple: true,
          options: [],
          onChange: this.setIncidentsFilter
        }
      }
    }
  },
  mounted() {
    this.load()
  },
  computed: {
    keywords() {
      return this.twitter_keywords.map((keyword) => ({ saved: true, keyword })).concat({ save: false, keyword: '' })
    },
    left() {
      return (window.innerWidth / 2) - (700 / 2)
    },
    top() {
      return (window.innerHeight / 2) - (640 / 2)
    },
    ...mapState('map', [
      'config'
    ]),
    ...mapGetters('auth', ['getUser']),
  },
  methods: {
    ...mapActions('map', [
      'toggleShowConfig',
      'setFilter'
    ]),
    saveKeyword(keyword) {
      this.twitter_keywords = this.twitter_keywords.concat(keyword)
      this.tweetStore.track(this.twitter_keywords)
    },
    removeKeyword(index) {
      this.twitter_keywords.splice(index, 1)
      this.tweetStore.track(this.twitter_keywords.length ? this.twitter_keywords : ['ajshdbahsdu723hbdhjwbehgfdwbjef'])
    },
    async load() {
      function sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }
      this.segmentationGroups = {}
      this.groups.forEach((group) => {
        if (!this.segmentationGroups[group.branch]) {
          this.segmentationGroups[group.branch] = []
        }
        this.segmentationGroups[group.branch].push(group)
      });
      /* eslint-disable no-await-in-loop */
      while (!this.$refs.form_cameras || !this.$refs.form_cameras.fields || !this.$refs.form_cameras.fields.groupId || !this.tweetStore.keywords) {
        await sleep(100)
      }
      this.twitter_keywords = this.twitter_keywords.concat(this.tweetStore.keywords)
      // this.twitter_keywords = this.tweetStore.keywords
      // console.log(this.keywords)
      this.$refs.form_cameras.fields.groupId.setOptions(this.segmentationGroups.cameras)
      this.$refs.form_buses.fields.groupId.setOptions(this.segmentationGroups.bus)
      this.$refs.form_lprs.fields.groupId.setOptions(this.segmentationGroups.lpr)
      this.$refs.form_devices.fields.groupId.setOptions(this.segmentationGroups.devices)
      this.$refs.form_sensors.fields.groupId.setOptions(this.segmentationGroups.sensor)
    },
    setCamerasFilter() {
      this.setFilter({ key: 'cameras', filters: this.$refs.form_cameras.getItem() })
    },
    setBusesFilter() {
      this.setFilter({ key: 'buses', filters: this.$refs.form_buses.getItem() })
    },
    setLprsFilter() {
      this.setFilter({ key: 'lprs', filters: this.$refs.form_lprs.getItem() })
    },
    setDevicesFilter() {
      this.setFilter({ key: 'devices', filters: this.$refs.form_devices.getItem() })
    },
    setSensorsFilter() {
      this.setFilter({ key: 'sensors', filters: this.$refs.form_sensors.getItem() })
    },
    setIncidentsFilter() {
      this.setFilter({ key: 'incidents', filters: this.$refs.form_incidents.getItem() })
    }
  },
}
</script>

<style></style>
